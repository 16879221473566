/* Change the border of the big play button. */
.workout-player .vjs-big-play-button {
    /* center play button */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    background-image: url("../../../resources/button-video-play.svg");
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;

    /* css animations */
    opacity: 1;
    transform: scale(1);
    transition: opacity .3s, transform .3s;

    /* remove default player button styling */
    background-color: transparent;
    border: none;
    box-shadow: none;
}

/* Remove big play button icon */
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    /*content: "\f101";*/
    content: "";
}

/* remove color when hovering over video-js element on the play button */
.video-js:hover .vjs-big-play-button {
    background-color: transparent;
}

.video-js:active .vjs-big-play-button {
    opacity: 0;
    transform: scale(2);
    transition: opacity .3s, transform .3s;
}

/* Hide picture in picture mode */
.vjs-picture-in-picture-control {
    visibility: hidden;
}