@font-face {
  font-family: DMSans;
  src: url("./resources/fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: DMSans-Medium;
  src: url("./resources/fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: DMSans-Bold;
  src: url("./resources/fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  src: url("./resources/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Medium;
  src: url("./resources/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Bold;
  src: url("./resources/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

html, body {
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
