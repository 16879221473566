// Can we override the default styles to add custom styles answer:
// https://github.com/jquense/react-big-calendar/issues/842

// List of class selectors that can be overwritten for custom styling:
// https://github.com/jquense/react-big-calendar/blob/884bece979e5c02603f0905ad25da07a9e7f48c7/src/sass/styles.scss

@import 'variables.scss';
@import '~react-big-calendar/lib/sass/styles.scss';

.my-rbc-event {
  height: 40px;
  overflow: hidden;
  background-color: rgba(70, 154, 30, 0.15);
}

.my-rbc-day-bg {
  flex: 1;

  & + .my-rbc-day-bg {
    border-left: 1px solid #DDD;
  }
}

.my-month-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $steel;
  font-size: 17px;
  font-weight: 500;
}

.event-text {
  color: #86a70d;
  margin: 0 12px 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-height: 14px;
  max-height: 28px;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.my-month-date-cell-label-container {
  padding: 4px;
  border-radius: 34px;
  margin: 12px;
  background-color: transparent;
  font-weight: normal;
}

// ============================================================================
// React Big Calendar style overrides/additions
// ============================================================================

// IMPORTANT: Removes the line separators from the month view header. None of the
// custom component interfaces allows us to override this effect.
.rbc-month-header .rbc-header {
  border-left: none;
}

// IMPORTANT: removes the default color set within the library. Allows our
// custom event and event wrapper components to control the color.
.rbc-event {
  background-color: transparent;

  &.rbc-selected {
    background-color: transparent;
  }

  height: inherit;
}

// IMPORTANT: For making the "Date label" row grow to fill the space
// unused by the events. Gives the effect of events anchored to the bottom
// of a date cell in the month view.
.rbc-row-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 85%;

  // contains the calendar day of the month
  & > :first-child {
    flex: 1;
    height: 50px;
  }
}

.rbc-month-view {
  position: relative;
  border: 1px solid $calendar-border;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;

  height: 100%; // ie-fix

  // Style Overrides
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}

// Changes the height of each event rectangle
.rbc-row-segment {
  padding: 0;
  height: 30px;
  // spacing between each segment when there is more than 1 event in a day
  margin-bottom: 4px;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  // forces the minimum height of each cell so that the user can view the cells in the calendar
  min-height: 120px;
}

.rbc-event-content {
  height: inherit;
}
